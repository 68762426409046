//hooks
import { useState } from 'react';
//css
import './CommentInput.css'
//firebase
import { ref, set } from 'firebase/database'
import { database } from '../../firebase/config';
//libraries
import { v4 } from 'uuid';
//assets
import success from '../../assets/success.gif'

const CommentInput = ({
    fetchComments
}) => {
    const [formStatus, setFormStatus] = useState(false)
    const [username, setUsername] = useState('')
    const [comment, setComment] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)

    const handleSubmitForm = (e) => {
        e.preventDefault()

        if(comment.trim().length === 0) return

        navigator.geolocation.getCurrentPosition(
            position => {
                
                const id = v4()
                set(ref(database, 'annotations/'+ id), {
                    comment: comment,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    name: username.length === 0 ? 'unknown' : username,
                    type: 'user',
                    uid: id
                })
                setUsername('')
                setComment('')  
                setFormStatus(false)
                fetchComments()
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                },2500)
            },
            error => {
                console.error(error);
            }
        ); 
    }

    return (
        <>
            {!formStatus && <div className='comment-btn' onClick={() => setFormStatus(true)}>
                <p>COMMENT</p>
            </div>}
            <div className={formStatus ? "input-wrapper-active" : "input-wrapper"}>
                <form className={formStatus ? "input-container-active" : "input-container"} onSubmit={handleSubmitForm}>
                    <button className='btn-close' onClick={() => setFormStatus(false)}>X</button>
                    <h4>Leave a comment for current location</h4>
                    <input className='comment-username' type="text" placeholder='LEAVE YOUR NAME...' onChange={(e) => setUsername(e.target.value)} value={username} />
                    <textarea className='comment-aria' cols="30" rows="10" placeholder='LEAVE COMMENT ABOUT YOUR CURRENT POSITION...' onChange={(e) => setComment(e.target.value)} value={comment} required></textarea>
                    <button className='btn-send'>SEND</button>
                </form>
            </div>
            {isSuccess && <img className='gif-success' src={success} alt='success sent message' />}
        </>
    );
}

export default CommentInput;