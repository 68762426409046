//hooks
import { useEffect, useRef, useState } from 'react';
//css
import './App.css';
//components
import MapGL from './components/Map/Map';
import { ref, onValue } from 'firebase/database'
import { database } from './firebase/config';
import CommentInput from './components/CommentInput/CommentInput';




function App() {
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [zoom, setZoom] = useState(16.5)
  const [drivers, setDrivers] = useState(null)
  const [comments, setComments] = useState(null)

  const userFirstLocation = useRef({ lng: null, lat: null })

  useEffect(() => {//fetching data from firebase, repacking and sending as a prop to the MAP component
    fetchDrivers()
    fetchComments()
  }, [])

  useEffect(() => {
    fetchUserLocation()
  }, []);

  const fetchUserLocation = () => { //catching position of user
    navigator.geolocation.getCurrentPosition(
      position => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);

        userFirstLocation.current.lat = position.coords.latitude
        userFirstLocation.current.lng = position.coords.longitude
      },
      error => {
        console.error(error);
      }
    );
  }

  const fetchDrivers = () => {//fetching drivers from firebase
    const driversRef = ref(database, 'drivers');
    onValue(driversRef, (snapshot) => {
      const data = snapshot.val();

      const drivers = []

      Object.keys(data).forEach((driverId) => {
        const driver = data[driverId]

        const driverObject = {
          id: driver.id,
          username: driver.username,
          email: driver.email,
          vehicle: driver.vehicle,
          asignedBy: driver.asignedBy,
          routes: Object.keys(driver.points).map((routeId) => {
            const route = driver.points[routeId]
            return {
              id: routeId,
              points: Object.keys(route).map((pointId) => {
                const point = route[pointId]
                return {
                  id: pointId,
                  point: { latitude: point.latitude, longitude: point.longitude }
                }
              })
            }
          })
        }
        drivers.push(driverObject)
      })
      setDrivers(drivers)
    })
  }

  const fetchComments = () => {//fetching comments from firebase
    const annotationsRef = ref(database, 'annotations');
    onValue(annotationsRef, (snapshot) => {
      const data = snapshot.val();

      const comments = []
      Object.keys(data).forEach((commentId) => {
        const comment = data[commentId]
        const commentObj = {
          id: comment.uid,
          type: comment.type,
          name: comment.name,
          text: comment.comment,
          coordinates: [comment.longitude, comment.latitude]
        }
        comments.push(commentObj)
      })
      setComments(comments)
    })
  }

  return (
    <div className="App">
      {latitude && longitude ? (
        <>
          <MapGL latitude={latitude} setLatitude={setLatitude} longitude={longitude} setLongitude={setLongitude} zoom={zoom} setZoom={setZoom} drivers={drivers} userFirstLocation={userFirstLocation.current} comments={comments} />
          <CommentInput fetchComments={fetchComments} />
        </>
      ) : (
        <div>Učitavanje mape...</div>
      )}
    </div>
  );
}

export default App;
