//hooks
import { useRef, useEffect } from "react";
//mapbox
import mapboxgl from "mapbox-gl";
//css
import './Map.css'
//img
import userMarker from '../../assets/user.png'
import commentMarker from '../../assets/comment.png'





mapboxgl.accessToken = process.env.REACT_APP_API_KEY

const MapGL = ({
    latitude,
    longitude,
    zoom,
    setLatitude,
    setLongitude,
    setZoom,
    drivers,
    userFirstLocation,
    comments
}) => {
    const map = useRef(null)//reference to the map from mapboxgl
    const mapContainer = useRef(null)//reference to the element that is a container of map

    useEffect(() => {//initial rendering of the map
        if (map.current) return
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [longitude, latitude],
            zoom: zoom
        })
    }, [longitude, latitude, zoom])

    useEffect(() => {//updating coordinates and zoom in main App
        if (!map.current) return;
        map.current.on('move', () => {
            setLongitude(map.current.getCenter().lng.toFixed(4));
            setLatitude(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    useEffect(() => {//setting map with all routes 
        if (drivers && comments) {
            const features = []

            drivers.forEach(driver => {//separating routes before rendering
                driver.routes.forEach(route => {
                    const coordinates = []

                    route.points.forEach(point => {
                        coordinates.push([point.point.longitude, point.point.latitude])
                    })
                    const feature = {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: coordinates
                        },
                        property: {
                            routeId: route.id,
                            driverId: driver.id,
                            driverName: driver.username,
                            vehicle: driver.vehicle,
                            driverEmail: driver.email,
                            asignedBy: driver.asignedBy
                        }
                    }
                    features.push(feature)
                })
            })
            //---------------------------------------------------------------MAP WITH ROUTES RENDERING
            map.current.on('load', () => {//when map is loaded first clear all previous layers
                if (map.current.getSource('routes')) {
                    map.removeLayer('routes')
                    map.removeSource('routes')
                }
                

                features.forEach(feature => {//run through routes make them geo-json format
                    map.current.addSource(feature.property.routeId, {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [feature]
                        }
                    })
                    map.current.addLayer({
                        id: feature.property.routeId,
                        type: 'line',
                        source: feature.property.routeId,
                        paint: {
                            'line-color': '#ff0000',
                            'line-width': 10
                        }
                    })
                    map.current.on('click', feature.property.routeId, (e) => {//set some events that will tell something more about specific route
                        
                    })
                    map.current.on('mouseenter', feature.property.routeId, () => {
                        map.current.setPaintProperty(feature.property.routeId, 'line-color', '#00ff00')
                        
                    })
                    map.current.on('mouseleave', feature.property.routeId, () => {
                        map.current.setPaintProperty(feature.property.routeId, 'line-color', '#ff0000')
                    })
                })
            })
            //---------------------------------------------------------------MAP WITH ROUTES RENDERING

            //---------------------------------------------------------------USER MARKER RENDERING
            const userEl = document.createElement('img')
            userEl.src = userMarker
            userEl.style.width = `${45}px`
            userEl.style.height = `${45}px`

            new mapboxgl.Marker(userEl)
            .setLngLat([userFirstLocation.lng, userFirstLocation.lat])
            .addTo(map.current)
            //---------------------------------------------------------------USER MARKER RENDERING

            //---------------------------------------------------------------COMMENTS MARKER RENDERING
            
            comments.forEach(comment => {
                const commentEl = document.createElement('img')
                commentEl.src = commentMarker
                commentEl.style.width = `${45}px`
                commentEl.style.height = `${55}px`
                commentEl.style.cursor = 'pointer'


                new mapboxgl.Marker(commentEl)
                .setLngLat(comment.coordinates)
                .setPopup(new mapboxgl.Popup({
                    offset: 30
                }).setHTML(`<h3>Name: ${comment.name}</h3><h4>Type: ${comment.type}</h4><p>Text: ${comment.text}</p>`))
                .addTo(map.current)
            })
            //---------------------------------------------------------------COMMENTS MARKER RENDERING
        }
    }, [drivers, comments, userFirstLocation.lat, userFirstLocation.lng])
    return (
        <>
            <div className="sidebar">
                Longitude: {longitude} | Latitude: {latitude} | Zoom: {zoom}
            </div>
            <div ref={mapContainer} className="map-container"></div>
        </>
    );
}

export default MapGL;