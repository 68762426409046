import { initializeApp } from "firebase/app";
//library
import { getDatabase } from 'firebase/database' 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "tampparitapp.firebaseapp.com",
  databaseURL: "https://tampparitapp-default-rtdb.firebaseio.com",
  projectId: "tampparitapp",
  storageBucket: "tampparitapp.appspot.com",
  messagingSenderId: "845751439137",
  appId: "1:845751439137:web:e39a9d1885de46f565b860",
  measurementId: "G-NXVLS8T1LB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app)